










import Vue from 'vue';

export default Vue.extend({
  name: 'BackButton',
  computed: {
    nested(): boolean {
      return this.$route.path.split('/').length > 2;
    },
  },
});
